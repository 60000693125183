<template>
<v-list dense>
  <v-subheader class="headline primary--text mb-3">{{tituloInput}}</v-subheader>
  <v-list-item-group
  v-model="seleccionadoInput"
    color="primary"
  >
    <v-list-item
      v-for="(item, i) in itemsInput"
      :key="i"
      style="border: 1px solid Gainsboro"
      class="primary--text"
      @click="clicItem(item.nombre, item.id, i)"
      :disabled="disabled"
    >
      <v-list-item-icon>
        <v-icon v-text="iconoInput" class="primary--text"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.nombre" class="text-left subtitle-1 primary--text"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</v-list>
</template>
<script>
export default {
  props: ['titulo', 'seleccionado', 'items', 'disabled', 'icono', 'estado'],
  methods: {
    clicItem (nombre, id, index) {
      this.$emit('clic-item', this.estado, nombre, id, index)
      if (this.quitar === true) {
        this.items.splice(index, 1)
      }
    }
  },
  computed: {
    tituloInput: {
      get: function () {
        return this.titulo
      },
      set: function (newValue) {
        this.$emit('update:titulo', newValue)
      }
    },
    seleccionadoInput: {
      get: function () {
        return this.seleccionado
      },
      set: function (newValue) {
        this.$emit('update:seleccionado', newValue)
      }
    },
    itemsInput: {
      get: function () {
        return this.items
      },
      set: function (newValue) {
        this.$emit('update:items', newValue)
      }
    },
    disabledInput: {
      get: function () {
        return this.disabled
      },
      set: function (newValue) {
        this.$emit('update:disabled', newValue)
      }
    },
    iconoInput: {
      get: function () {
        return this.icono
      },
      set: function (newValue) {
        this.$emit('update:icono', newValue)
      }
    },
    estadoInput: {
      get: function () {
        return this.estado
      },
      set: function (newValue) {
        this.$emit('update:estado', newValue)
      }
    }
  }
}
</script>
