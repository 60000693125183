<template>
  <div>
    <v-btn color="primary" @click="verDialogCopiar()">COPIAR</v-btn>
    <DialogSimple
      :dialog.sync="dialogInput"
      :titulo="tituloInput"
      :width="400"
    >
      <v-form ref="form" v-model="valid">
        <v-text-field
          :label="labelInput"
          v-model="copiaInput"
          style="margin-bottom: -5px;"
          :rules="[requerido]"
          maxlength="45"
          @change="existe"
          @keydown="checkKeyDownAlphaNumeric($event)"
        ></v-text-field>
        <v-row justify="center">
          <v-btn color="primary" class="mt-4" @click="copiar" :disabled="!valid">COPIAR</v-btn>
        </v-row>
      </v-form>
    </DialogSimple>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import DialogSimple from '@/components/dialogsimple.vue'

export default {
  data: () => ({
    estado: 'NEW',
    requerido: v => !!v || 'Este campo es obligatorio',
    valid: false
  }),
  components: {
    DialogSimple
  },
  mixins: [
    Globales
  ],
  props: ['api_existe', 'api_copiar', 'api_lista', 'api_listafiltro', 'titulo', 'dialog', 'loading',
    'label', 'nombre', 'copia', 'filtrar', 'store', 'error', 'modo'],
  methods: {
    async existe () {
      const response = await ApiCon.get2(this.api_existe, this.copiaInput, this.$session.get('usuario'))
      if (response.status === 200) {
        if (response.data === true) {
          this.copiaInput = ''
          alert('El nombre ya se encuentra registrado.')
        }
      } else { alert('error existeCopia') }
    },
    async copiar () {
      if (this.copiaInput !== '') {
        const ins = {
          nombre: this.nombreInput,
          notas: this.copiaInput,
          usuario: this.$session.get('usuario')
        }
        this.loadingInput = true
        const response = await ApiCon.post(this.api_copiarInput, ins)
        if (response.status === 200) {
          if (this.modoInput === 'select') {
            if (this.filtrarInput === false) {
              this.cargarTabla(this.api_listaInput, this.storeInput, this.errorInput)
            } else {
              this.cargarTablaFiltro(this.api_listafiltroInput, this.storeInput, this.errorInput, this.copiaInput)
            }
          } else {
            //
          }
          this.nombreInput = this.copiaInput
          this.copiaInput = ''
          this.dialogInput = false
        } else { alert('Error copiar') }
        this.loadingInput = false
      } else { alert('El nombre no puede estar vacío ni existir previamente.') }
    },
    verDialogCopiar () {
      if (this.nombreInput !== '' & this.nombreInput !== null) {
        this.dialogInput = true
      } else {
        alert('Debe seleccionar un item primero.')
      }
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    api_existeInput: {
      get: function () {
        return this.api_existe
      },
      set: function (newValue) {
        this.$emit('update:api_existe', newValue)
      }
    },
    api_copiarInput: {
      get: function () {
        return this.api_copiar
      },
      set: function (newValue) {
        this.$emit('update:api_copiar', newValue)
      }
    },
    api_listaInput: {
      get: function () {
        return this.api_lista
      },
      set: function (newValue) {
        this.$emit('update:api_lista', newValue)
      }
    },
    api_listafiltroInput: {
      get: function () {
        return this.api_listafiltro
      },
      set: function (newValue) {
        this.$emit('update:api_listafiltro', newValue)
      }
    },
    tituloInput: {
      get: function () {
        return this.titulo
      },
      set: function (newValue) {
        this.$emit('update:titulo', newValue)
      }
    },
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    loadingInput: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    labelInput: {
      get: function () {
        return this.label
      },
      set: function (newValue) {
        this.$emit('update:label', newValue)
      }
    },
    nombreInput: {
      get: function () {
        return this.nombre
      },
      set: function (newValue) {
        this.$emit('update:nombre', newValue)
      }
    },
    copiaInput: {
      get: function () {
        return this.copia
      },
      set: function (newValue) {
        this.$emit('update:copia', newValue)
      }
    },
    filtrarInput: {
      get: function () {
        return this.filtrar
      },
      set: function (newValue) {
        this.$emit('update:filtrar', newValue)
      }
    },
    storeInput: {
      get: function () {
        return this.store
      },
      set: function (newValue) {
        this.$emit('update:store', newValue)
      }
    },
    errorInput: {
      get: function () {
        return this.error
      },
      set: function (newValue) {
        this.$emit('update:error', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog === true) {
        this.copia = ''
      }
    }
  }
}
</script>
