<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading">
    <p class="display-2 primary--text font-weight-regular">{{titulo}}</p>
    <p class="display-1 primary--text font-weight-regular" style="margin-top: -10px;">Paciente: {{paciente}}</p>
    <v-form ref="form" v-model="valid">
      <v-row align="center" justify="center" class="mb-4">
        <v-btn
          color="primary"
          class="mr-2"
          @click="muestrasRecibidas"
        >
          MUESTRAS RECEPCIONADAS
        </v-btn>
        <v-btn
          color="primary"
          @click="cargarCIE"
        >
          CONFIG. ETIQUETAS
        </v-btn>
      </v-row>
      <v-row class="mb-5">
        <v-col
          cols="12"
          sm="6"
          v-show="verRecibir"
        >
          <ListaSimple
            :titulo.sync="lpendientesTitulo"
            :seleccionado.sync="selectedItem"
            :items.sync="itemspendientes"
            :disabled.sync="disableLista1"
            @clic-item="clicItem"
            :icono.sync="iconoLista"
            :estado.sync="lpendientesEstado"
          />
          <v-row align="center" justify="center" v-show="verbotonespendientes">
            <v-btn
              color="success"
              class="mt-3 mr-2"
              @click="agregarTodas"
            >
            AGREGAR TODAS
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          :sm="colPendientes"
        >
          <ListaSimple
            :titulo.sync="lrecibidasTitulo"
            :seleccionado.sync="selectedItem2"
            :items.sync="itemsrecibidos"
            :disabled.sync="disableLista2"
            @clic-item="clicItem"
            :icono.sync="iconoLista"
            :estado.sync="lrecibidasEstado"
          />
          <v-row align="center" justify="center" v-show="verbotonesrecibidas">
            <v-btn
              color="error"
              class="mt-3 mr-2"
              @click="quitarTodo"
              v-show="verBotones"
            >
            QUITAR TODO
            </v-btn>
            <v-menu
              offset-y
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="mt-3"
                  v-show="verImprTodo"
                >
                  IMPRIMIR TODO
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  @click="imprTodo(index)"
                >
                  <v-list-item-title class="primary--text" style="font-weight: bold;">{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="4">
        </v-col>
        <v-col cols="12" sm="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechamuestra"
                label="Fecha Toma Muestra"
                persistent-hint
                v-bind="attrs"
                v-on="on"
                readonly
                v-show="fechamuesvisible"
                style="margin-bottom: -5px;"
                :rules="[requerido]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              locale="es-CL"
              :first-day-of-week="1"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Hora Toma Muestra"
                readonly
                v-bind="attrs"
                v-on="on"
                v-show="horamuesvisible"
                :rules="[requerido]"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4">
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="margin-bottom: 40px;">
        <v-btn
          color="primary"
          class="mt-3 mr-2"
          @click="guardarDatos"
          v-show="verBotones"
          :disabled="!valid"
        >
        GUARDAR
        </v-btn>
      </v-row>
    </v-form>
    <DialogOpciones
      :dialog.sync="dialogmuestras"
      :titulo.sync="dmuestrasTitulo"
      :opciones.sync="botonesopciones"
      :width.sync="dmuestrasAncho"
      @clic-boton="clicBoton"
    />
    <DialogSimple
      :dialog.sync="dialogexamenes"
      :titulo.sync="dexamenesTitulo"
      :width.sync="dexamenesAncho "
      :loading.sync="loadingexamenes"
    >
      <v-row
        justify="center"
        align="center"
        class="mb-2"
      >
        <div v-for="examen in examenes" v-bind:key="examen">
          <p class="primary--text headline">*{{ examen }}*</p>
        </div>
      </v-row>
    </DialogSimple>
    <DialogSimple
      :dialog.sync="dialogrecibidas"
      :width.sync="drecibidasAncho"
      :loading.sync="loadingoldfile"
    >
      <v-row
        justify="center"
        align="center"
        class="mb-2"
      >
        <v-text-field
          v-model="filtrar"
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="mrecibidas"
          :search="filtrar"
          :mobile-breakpoint='0'
          @click:row="opcionesMrecibidas"
          class="mt-5"
          :loading="!mrecibidas.length"
          loading-text="Cargando datos... Por favor, espere..."
        >
        </v-data-table>
      </v-row>
    </DialogSimple>
    <DialogSimple
      :dialog.sync="dialogCIE"
      :width="500"
      :loading.sync="loadingCIE"
      :titulo="'CONFIGURACION IMPRESIÓN DE ETIQUETAS'"
    >
      <v-form ref="formCIE" v-model="validCIE">
        <v-text-field
          label="Ancho Etiqueta (mm.)"
          v-model="cie.ancho"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-text-field
          label="Alto Etiqueta (mm.)"
          v-model="cie.alto"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-text-field
          label="Margen Inferior (mm.)"
          v-model="cie.mb"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-text-field
          label="Margen Izquierdo (mm.)"
          v-model="cie.ml"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-text-field
          label="Margen Derecho (mm.)"
          v-model="cie.mr"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-text-field
          label="Margen Superior (mm.)"
          v-model="cie.mt"
          :rules="[requerido]"
          counter
          maxlength="5"
          style="margin-bottom: -5px;"
        ></v-text-field>
        <v-row align="center" justify="center" class="mt-3 mb-4">
          <v-btn
            color="primary"
            @click="guardarCIE"
            :disabled="!validCIE"
          >
          GUARDAR
          </v-btn>
        </v-row>
      </v-form>
    </DialogSimple>
  </DialogVentana>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import DialogOpciones from '@/components/dialogopciones.vue'
import DialogVentana from '@/components/dialogventana.vue'
import DialogSimple from '@/components/dialogsimple.vue'
import ListaSimple from '@/components/listasimple.vue'

export default {
  data: () => ({
    titulo: 'Recibir Muestras',
    paciente: '',
    examenes: {},
    dialog: false,
    selectedItem: null,
    itemspendientes: [],
    itemsrecibidos: [
      { id: 0, nombre: 'No se han recibido muestras' }
    ],
    selectedItem2: null,
    dialogmuestras: false,
    botonesopciones: [],
    tituloopciones: '',
    tipomuestra: '',
    verbotonesrecibidas: false,
    verbotonespendientes: true,
    items: [
      { title: 'CON CODIGO' },
      { title: 'CON DETALLE' }
    ],
    disableLista1: false,
    disableLista2: true,
    loading: false,
    verRecibir: true,
    colPendientes: '6',
    verBotones: true,
    idmuestra: 0,
    dialogexamenes: false,
    dialogrecibidas: false,
    filtrar: '',
    headers: [
      { text: 'MUESTRAS RECIBIDAS', align: 'start', value: 'nombre' }
    ],
    mrecibidas: [],
    verImprTodo: false,
    loadingoldfile: false,
    loadingexamenes: false,
    dmuestrasTitulo: '',
    dmuestrasAncho: 350,
    dexamenesTitulo: '',
    dexamenesAncho: 350,
    drecibidasAncho: 640,
    lpendientesTitulo: 'Muestras Pendientes',
    iconoLista: 'mdi-water',
    lpendientesEstado: 'pendientes',
    lrecibidasTitulo: 'Muestras Recibidas',
    lrecibidasEstado: 'recibidas',
    dialogCIE: false,
    loadingCIE: false,
    validCIE: false,
    cie: [],
    requerido: v => !!v || 'Este campo es obligatorio',
    date: new Date().toISOString().substr(0, 10),
    menu1: false,
    fechamuestra: '',
    fechamuesvisible: true,
    time: null,
    menu2: false,
    modal2: false,
    valid: false,
    horamuesvisible: true
  }),
  mixins: [
    Globales
  ],
  components: {
    DialogOpciones,
    DialogVentana,
    DialogSimple,
    ListaSimple
  },
  props: ['dialogRM'],
  methods: {
    clicItem (estado, nombre, id, index) {
      this.opcionesItems(estado, nombre, id)
    },
    opcionesItems (modo, tipo, id) {
      this.botonesopciones = []
      this.tituloopciones = tipo
      this.idmuestra = id
      if (modo === 'pendientes') {
        this.tipomuestra = 'pendiente'
        this.botonesopciones = [
          { id: 1, nombre: 'Agregar Muestra', color: 'success', accion: 'agregarMuestra' },
          { id: 2, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' }
        ]
      } else if (modo === 'recibidas') {
        this.tipomuestra = 'recibida'
        if (this.verRecibir === true) {
          this.botonesopciones = [
            { id: 3, nombre: 'Quitar Muestra', color: 'error ', accion: 'quitarMuestra' },
            { id: 4, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' }
          ]
        } else {
          this.botonesopciones = [
            { id: 1, nombre: 'Imprimir etiqueta código', color: 'primary', accion: 'impCod' },
            { id: 2, nombre: 'Imprimir etiqueta detalle', color: 'primary', accion: 'impDet' },
            { id: 3, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' }
          ]
        }
      }
      if (id !== 0) {
        this.dialogmuestras = true
      } else {
        this.selectedItem = null
        this.selectedItem2 = null
      }
    },
    cerrarOpcionesItems () {
      this.selectedItem = null
      this.selectedItem2 = null
      this.dialogmuestras = false
      this.verEtiq = false
    },
    quitarTodo () {
      if (this.itemspendientes[0].id === 0) {
        this.itemspendientes = []
        this.verbotonesrecibidas = false
        this.verbotonespendientes = true
        this.disableLista1 = false
      }
      var index = 0
      var items = this.itemsrecibidos.length
      while (items > index) {
        this.itemspendientes.push(this.itemsrecibidos[index])
        index++
      }
      this.itemsrecibidos = []
      this.sinRecibidas()
    },
    agregarTodas () {
      if (this.itemsrecibidos[0].id === 0) {
        this.itemsrecibidos = []
        this.verbotonesrecibidas = true
        this.verbotonespendientes = false
        this.disableLista2 = false
      }
      var index = 0
      var items = this.itemspendientes.length
      while (items > index) {
        this.itemsrecibidos.push(this.itemspendientes[index])
        index++
      }
      this.itemspendientes = []
      this.sinPendientes()
    },
    sinPendientes () {
      if (this.itemspendientes.length === 0) {
        this.itemspendientes = [
          { id: 0, nombre: 'No hay muestras pendientes' }
        ]
        this.verbotonespendientes = false
        this.verbotonesrecibidas = true
        this.disableLista1 = true
      }
    },
    sinRecibidas () {
      if (this.itemsrecibidos.length === 0) {
        this.itemsrecibidos = [
          { id: 0, nombre: 'No se han recibido muestras' }
        ]
        this.verbotonespendientes = true
        this.verbotonesrecibidas = false
        this.disableLista2 = true
      }
    },
    agregarMuestra () {
      if (this.itemsrecibidos[0].id === 0) {
        this.itemsrecibidos = []
        this.verbotonesrecibidas = true
        this.disableLista2 = false
      }
      this.itemsrecibidos.push(this.itemspendientes[this.selectedItem])
      this.itemspendientes.splice(this.selectedItem, 1)
      this.sinPendientes()
      this.selectedItem = null
      this.dialogmuestras = false
    },
    quitarMuestra () {
      if (this.itemspendientes[0].id === 0) {
        this.itemspendientes = []
        this.verbotonespendientes = true
        this.disableLista1 = false
      }
      this.itemspendientes.push(this.itemsrecibidos[this.selectedItem2])
      this.itemsrecibidos.splice(this.selectedItem2, 1)
      this.sinRecibidas()
      this.selectedItem2 = null
      this.dialogmuestras = false
    },
    imprTodo (index) {
      if (index === 0) {
        this.imprTodoCodigo()
      } else if (index === 1) {
        this.imprTodoDetalle()
      }
    },
    imprTodoCodigo () {
      this.imprimirEtiquetaTodo('Cod')
    },
    imprTodoDetalle () {
      this.imprimirEtiquetaTodo('Det')
    },
    impCod () {
      this.imprimirEtiqueta('Cod')
    },
    impDet () {
      this.imprimirEtiqueta('Det')
    },
    async imprimirEtiquetaTodo (tipo) {
      var muestras = []
      for (let index = 0; index < this.itemsrecibidos.length; index++) {
        muestras.push(this.itemsrecibidos[index].id)
      }
      const ins = {
        muestras: muestras,
        usuario: this.$session.get('usuario'),
        tipo: tipo
      }
      this.loadingoldfile = true
      this.loading = true
      this.dialogmuestras = false
      const response = await ApiCon.postDownload('examenmuestras/imprimirmultietiqueta', ins)
      if (response.status === 200) {
        this.procesaPDF(response.data, 'etiqueta_multi')
      } else { alert('Ha ocurrido un error con la generación de la etiqueta multi') }
      this.loadingoldfile = false
      this.loading = false
    },
    async imprimirEtiqueta (tipo) {
      const ins = {
        MuestraIdmuestra: this.idmuestra,
        NombreEm: this.$session.get('usuario'),
        EstadoEm: tipo
      }
      this.loadingoldfile = true
      this.loading = true
      this.dialogmuestras = false
      const response = await ApiCon.postDownload('examenmuestras/imprimiretiqueta', ins)
      if (response.status === 200) {
        this.procesaPDF(response.data, 'etiqueta')
      } else { alert('Ha ocurrido un error con la generación de la etiqueta') }
      this.loadingoldfile = false
      this.loading = false
    },
    async cargarNombrePaciente () {
      this.loading = true
      const response = await ApiCon.get1('pacientes/nombreconid', this.$store.state.ids)
      if (response.status === 200) {
        this.paciente = response.data
      } else { alert('Error en cargarNombrePaciente...') }
      this.loading = false
    },
    async cargarMuestras () {
      this.loading = true
      const response = await ApiCon.get1('examenmuestras/pendientes', this.$store.state.ids)
      if (response.status === 200) {
        this.itemspendientes = response.data
        this.sinPendientes()
        this.sinRecibidas()
        this.verbotonesrecibidas = false
      } else { alert('Error en cargarMuestras...') }
      this.loading = false
    },
    async muestrasRecibidas () {
      this.loading = true
      const response = await ApiCon.get1('examenmuestras/recibidas', this.$store.state.ids)
      if (response.status === 200) {
        this.mrecibidas = response.data
        this.dialogrecibidas = true
      } else { alert('Error en muestrasRecibidas...') }
      this.loading = false
    },
    async verExamenes () {
      this.examenes = []
      this.loadingexamenes = true
      const response = await ApiCon.get1('examenmuestras/examenesasociados', this.idmuestra)
      if (response.status === 200) {
        this.examenes = response.data
        if (this.modoexamen === true) {

        }
      } else { alert('Error en verExamenes...') }
      this.loadingexamenes = false
    },
    opcionesMrecibidas (index) {
      this.botonesopciones = []
      this.idmuestra = index.id
      this.tipomuestra = 'recibidas'
      this.tituloopciones = index.muestra
      this.botonesopciones = [
        { id: 1, nombre: 'Imprimir etiqueta código', color: 'primary', accion: 'impCod' },
        { id: 2, nombre: 'Imprimir etiqueta detalle', color: 'primary', accion: 'impDet' },
        { id: 3, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' }
      ]
      this.verExamenes()
      this.verEtiq = true
      this.dialogmuestras = true
    },
    clicBoton (accion) {
      if (accion === 'agregarMuestra') {
        this.agregarMuestra()
      } else if (accion === 'detallesExamenes') {
        this.verExamenes()
        this.dialogexamenes = true
      } else if (accion === 'impCod') {
        this.impCod()
      } else if (accion === 'impDet') {
        this.impDet()
      } else if (accion === 'quitarMuestra') {
        this.quitarMuestra()
      }
    },
    async guardarDatos () {
      if (this.validaciones() === true) {
        var muestras = []
        for (let index = 0; index < this.itemsrecibidos.length; index++) {
          muestras.push(this.itemsrecibidos[index].id)
        }
        const ins = {
          muestras: muestras,
          fecha: this.fechamuestra,
          hora: this.time
        }
        this.loading = true
        const response = await ApiCon.post('examenmuestras/recibirmuestras', ins)
        if (response.status === 200) {
          alert('Las muestras seleccionadas han sido recibidas')
          this.verRecibir = false
          this.colPendientes = '12'
          this.verBotones = false
          this.verImprTodo = true
          this.fechamuesvisible = false
          this.horamuesvisible = false
          this.$refs.form.reset()
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else { alert('Debe recibir al menos una muestra y completar la fecha y hora de toma muestras.') }
    },
    async cargarCIE () {
      this.loadingCIE = true
      const response = await ApiCon.get1('examenmuestras/configcie', this.$session.get('usuario'))
      if (response.status === 200) {
        this.cie = response.data
        this.dialogCIE = true
      } else { alert('error cargarCIE') }
      this.loadingCIE = false
    },
    async guardarCIE () {
      this.loadingCIE = true
      const ins = {
        IdconfigImpEtiq: this.cie.id,
        AnchoCie: this.cie.ancho,
        AltoCie: this.cie.alto,
        MbCie: this.cie.mb,
        MlCie: this.cie.ml,
        MrCie: this.cie.mr,
        MtCie: this.cie.mt
      }
      const response = await ApiCon.post('examenmuestras/guardarcie', ins)
      if (response.status === 200) {
        alert('Configuración para la impresión de etiquetas editada')
        this.dialogCIE = false
      } else { alert('error guardarCIE') }
      this.loadingCIE = false
    },
    cargarDatos () {
      this.cargarNombrePaciente()
      this.cargarMuestras()
      this.fechamuestra = this.formatDate(new Date().toISOString().substr(0, 10))
    },
    reset () {
      this.selectedItem = null
      this.selectedItem2 = null
      this.itemspendientes = []
      this.itemsrecibidos = [
        { id: 0, muestra: 'No se han recibido muestras' }
      ]
      this.verbotonesrecibidas = false
      this.verbotonespendientes = true
      this.disableLista1 = false
      this.disableLista2 = true
      this.verRecibir = true
      this.colPendientes = '6'
      this.verBotones = true
      this.modImpEtiq = ''
      this.idmuestra = 0
      this.filtrar = ''
      this.mrecibidas = []
      this.examenes = []
      this.verImprTodo = false
      this.fechamuesvisible = true
      this.horamuesvisible = true
    },
    validaciones () {
      if (this.itemsrecibidos.length > 0 & this.itemsrecibidos[0].id !== 0 & this.fechamuestra !== '') {
        return true
      } else { return false }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogRM
      },
      set: function (newValue) {
        this.$emit('update:dialogRM', newValue)
      }
    }
  },
  watch: {
    dialogRM () {
      if (this.dialogRM === true) {
        this.cargarDatos()
      } else {
        this.reset()
        this.$refs.form.reset()
      }
    },
    dialogmuestras () {
      if (this.dialogmuestras === true) {
        this.dmuestrasTitulo = 'Opciones muestras ' + this.tipomuestra + ': ' + this.tituloopciones
      } else {
        this.selectedItem = null
        this.selectedItem2 = null
      }
    },
    dialogexamenes () {
      if (this.dialogexamenes === true) {
        this.dexamenesTitulo = 'Examenes muestra ' + this.tipomuestra + ': ' + this.tituloopciones
      }
    },
    dialogrecibidas () {
      if (this.dialogrecibidas === true) {
        this.dmuestrasTitulo = 'Opciones muestras ' + this.tipomuestra + ': ' + this.tituloopciones
      }
    },
    date (val) {
      this.fechamuestra = this.formatDate(this.date)
    }
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
