<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading">
    <v-row no-gutters class="text-center" wrap align="center">
      <v-col
        cols="12"
        sm="12"
      >
        <v-card style="box-shadow: none;">
        <v-card-text>
          <p class="display-2 primary--text font-weight-regular">{{titulo}}</p>
          <p class="display-1 primary--text font-weight-regular mb-5" style="margin-top: -10px;">Paciente: {{paciente}}</p>
          <v-form ref="form" v-model="valid">
            <v-row class="text-left pl-5 pr-5 mb-5">
              <v-col
                cols="12"
                sm="12"
              >
              <v-autocomplete
                label="SELECCIONE EXÁMENES PARA ASIGNAR"
                :items="examenes"
                v-model="examenesok"
                chips
                multiple
                deletable-chips
                :rules="[requerido]"
              ></v-autocomplete>
                <v-row align="center" justify="center">
                  <transition
                    name="fade"
                    mode="out-in"
                  >
                    <v-btn
                      color="error"
                      @click="preQuitarTodo"
                      v-if="examenesok.length > 0"
                      class="mr-2"
                    >
                      QUITAR TODOS
                    </v-btn>
                  </transition>
                    <v-btn
                      color="primary"
                      @click="opcionesExamenesDialog = true"
                    >
                      OPCIONES DE EXAMENES
                    </v-btn>
              </v-row>
              </v-col>
            </v-row>
            <v-row class="text-left pl-5 pr-5">
              <v-col
                cols="12"
                sm="6"
              >
              <v-text-field
                v-model="medico"
                label="Médico Solicitante"
                counter
                maxlength="70"
                :rules="[requerido]"
                @change="medico = 'Dr(a) ' + medico"
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
              <v-textarea
                v-model="notas"
                auto-grow
                filled
                label="Notas"
                rows="3"
                counter
                maxlength="200"
                :rules="[requerido]"
              ></v-textarea>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" style="margin-bottom: 40px;">
              <v-btn
                color="primary"
                class="mt-3 mr-2"
                @click="guardarDatos"
                :disabled="!valid"
              >
              GUARDAR
              </v-btn>
              <v-btn
                color="primary"
                class="mt-3"
                @click="guardarRecibirMuestras"
                :disabled="!valid"
              >
              GUARDAR Y RECIBIR MUESTRAS
              </v-btn>
            </v-row>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <DialogSimple
        :dialog.sync="dialog"
        :titulo.sync="drecibirMTitulo"
        :width.sync="drecibirMAncho"
      >
        <v-row
          justify="center"
          align="center"
          style="padding: 10px;"
        >
        <v-btn
            color="primary"
            class="mt-3 mr-2"
            @click="dialog = false"
          >
          NO
          </v-btn>
          <v-btn
            color="error"
            class="mt-3"
            @click="quitarTodo"
          >
          SI
          </v-btn>
        </v-row>
      </DialogSimple>
      <RecibirMuestras :dialogRM.sync="dialogRecibirMuestras"/>
      <DialogSimple
        :dialog.sync="opcionesExamenesDialog"
        titulo="OPCIONES DE EXÁMENES"
        :width="400"
      >
        <SelectCrud
          :items="$store.state.examenes"
          store="cambiarExamenes"
          metodo="cargarListaExamenes"
          :model.sync="examen"
          api_carga="examenes/listaexamenesnoplantilla"
          :dialog.sync="dialogExamen"
          label="Examen"
          :modo.sync="modoExamen"
          nnuevo="NUEVO">
        <Examen :modo.sync="modoExamen" :dialogE.sync="dialogExamen" :nexamen.sync="examen" origen="select"/>
      </SelectCrud>
      <v-row justify="center" class="mb-3">
        <BotonCopiar
          api_existe="examenes/existe"
          api_copiar="examenes/copiar"
          api_lista="examenes/listaexamenesnoplantilla"
          titulo="Copiar Examen"
          :dialog.sync="dialogCopiarExamen"
          :loading.sync="loading"
          label="Nombre examen copia"
          :nombre.sync="examen"
          :copia.sync="copiaExamen"
          store="cambiarExamenes"
          error="cargarListaExamenes"
          modo="select"
        />
      </v-row>
    </DialogSimple>
    </v-row>
  </DialogVentana>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import RecibirMuestras from '@/views/Paciente/recibirmuestras.vue'
import DialogVentana from '@/components/dialogventana.vue'
import DialogSimple from '@/components/dialogsimple.vue'
import SelectCrud from '@/components/selectcrud.vue'
import Examen from '@/views/examen.vue'
import BotonCopiar from '@/components/botoncopiar.vue'

export default {
  data: () => ({
    titulo: 'Asignar Exámenes',
    paciente: '',
    examenes: [],
    examenesok: [],
    medico: '',
    notas: '',
    dialog: false,
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    examenesback: 'white',
    loading: false,
    recibir: false,
    dialogRecibirMuestras: false,
    drecibirMTitulo: '¿Está seguro que desea quitar todos los exámenes ya seleccionados?',
    drecibirMAncho: 350,
    opcionesExamenesDialog: false,
    examen: '',
    modoExamen: 'NUEVO',
    dialogExamen: false,
    dialogCopiarExamen: false,
    copiaExamen: ''
  }),
  mixins: [
    Globales
  ],
  props: ['dialogAE'],
  components: {
    RecibirMuestras,
    DialogVentana,
    DialogSimple,
    SelectCrud,
    Examen,
    BotonCopiar
  },
  methods: {
    preQuitarTodo () {
      this.dialog = true
    },
    quitarTodo () {
      this.examenesok = []
      this.dialog = false
    },
    async cargarNombrePaciente () {
      this.loading = true
      const response = await ApiCon.get1('pacientes/nombreconid', this.$store.state.ids)
      if (response.status === 200) {
        this.paciente = response.data
      } else { alert('Error en cargarNombrePaciente...') }
      this.loading = false
    },
    async cargarExamenes () {
      this.loading = true
      const response = await ApiCon.get1('examenes/listaexamenes', this.$session.get('usuario'))
      if (response.status === 200) {
        this.examenes = response.data
      } else { alert('Error en cargarExamenes...') }
      this.loading = false
    },
    async guardarDatos () {
      if (this.valid === true & this.examenesok.length > 0) {
        const ins = {
          idpaciente: this.$store.state.ids,
          examenes: this.examenesok,
          solicitante: this.medico,
          notas: this.notas,
          usuario: this.$session.get('usuario')
        }
        this.loading = true
        const response = await ApiCon.post('examenordenes/insertar', ins)
        if (response.status === 200) {
          alert('Exámenes asignados a ' + this.paciente + '. Orden de exámenes creada :)')
          if (this.recibir === true) {
            this.dialogRecibirMuestras = true
          }
          this.reset()
          this.$refs.form.reset()
          this.dialogInput = false
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else {
        alert('Debe asignar al menos un examen.')
      }
    },
    guardarRecibirMuestras () {
      this.recibir = true
      this.guardarDatos()
    },
    cargarDatos () {
      this.recibir = false
      this.cargarNombrePaciente()
      this.cargarExamenes()
    },
    reset () {
      this.paciente = ''
      this.examenes = []
      this.examenesok = []
      this.medico = ''
      this.notas = ''
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogAE
      },
      set: function (newValue) {
        this.$emit('update:dialogAE', newValue)
      }
    }
  },
  watch: {
    dialogAE () {
      if (this.dialogAE === true) {
        this.cargarDatos()
      } else {
        this.$refs.form.reset()
        this.reset()
      }
    },
    dialogCopiarExamen () {
      if (this.dialogCopiarExamen === false) {
        this.cargarExamenes()
      }
    },
    dialogExamen () {
      if (this.dialogExamen === false) {
        this.cargarExamenes()
      }
    }
  }
}
</script>

<style scoped>
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .theme--light.v-chip  {
      font-size: 1.2rem !important;
      font-weight:500;
      color: white;
      background-color: #1976d2 !important;
  }
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .v-chip .v-chip__close.v-icon.v-icon--right  {
      color: white;
  }
</style>
