<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading">
   <v-row no-gutters class="text-center" wrap align="center">
    <v-col
      cols="12"
      sm="12"
    >
      <v-card style="box-shadow: none;">
      <v-card-text>
        <p class="display-2 primary--text font-weight-regular mt-4">{{titulo}}</p>
        <v-form ref="form" v-model="valid">
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :label="rutLabel"
              v-model="datos.rut"
              @change="validarRut"
              :rules="[requerido]"
              counter
              maxlength="12"
              style="margin-bottom: -5px;"
              :disabled="disabledRut"
              placeholder="11111111-1"
            ></v-text-field>
            <v-text-field
              label="Nombre"
              v-model="datos.nombre"
              :rules="[requerido]"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
              @change="nombreToUpper"
            ></v-text-field>
            <v-text-field
              label="Segundo Nombre"
              v-model="datos.segnombre"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
              @change="segnombreToUpper"
            ></v-text-field>
            <v-text-field
              label="Apellido Paterno"
              v-model="datos.appaterno"
              :rules="[requerido]"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
              @change="appaternoToUpper"
            ></v-text-field>
            <v-text-field
              label="Apellido Materno"
              v-model="datos.apmaterno"
              :rules="[requerido]"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
              @change="apmaternoToUpper"
            ></v-text-field>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datos.fechanac"
                  label="Fecha de Nacimiento"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  v-show="fechanacvisible"
                  style="margin-bottom: -5px;"
                  :rules="[requerido]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                locale="es-CL"
                :first-day-of-week="1"
                @input="menu1 = false"
                @change="fechanacback = 'white'"
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
              label="Sexo"
              :items="sexos"
              v-model="datos.sexo"
              clearable
              style="margin-bottom: -5px;"
              no-data-text="Cargando datos..."
              :rules="[requerido]"
            ></v-autocomplete>
            <!-- <SucursalSelect v-show="botonpass" :sucursal.sync="sucursal" :backcolor.sync="sucursalback"/> -->
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              :label="emailLabel"
              v-model="datos.email"
              :rules="emailRules"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Celular"
              v-model="datos.celular"
              counter
              maxlength="12"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Previsión"
              v-model="datos.prevision"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
              v-show="verPrevision"
            ></v-text-field>
            <v-autocomplete
              label="Comuna"
              :items="comunas"
              v-model="datos.comuna"
              clearable
              style="margin-bottom: -5px;"
              no-data-text="Cargando datos..."
              :rules="[requerido]"
            ></v-autocomplete>
            <v-text-field
              label="Dirección"
              v-model="datos.direccion"
              counter
              maxlength="70"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Sector"
              v-model="datos.sector"
              counter
              maxlength="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-autocomplete
              label="Tipo Cuenta"
              :items="tiposCuenta"
              v-model="datos.tipo_cuenta"
              clearable
              style="margin-bottom: -5px;"
              no-data-text="Cargando datos..."
              v-show="verTipoCuenta"
              :disabled="disableTipoCuenta"
              :rules="verTipoCuenta === true ? [requerido] : []"
              @change="tipoCuentaChange"
            ></v-autocomplete>
            <v-file-input
              :rules="reglaUpload"
              accept="image/jpeg"
              :placeholder="labelFirma"
              prepend-icon="mdi-camera"
              show-size
              @change="preFirmaUpload"
              v-show="firmaVisible"
            ></v-file-input>
            <v-btn
              :color="colorEditaPass"
              class="mt-3"
              @click="verDialogPass"
              v-show="botonpass"
            >
            {{botonEditaPass}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn
            color="primary"
            class="mt-3 mr-2"
            @click="guardarDatos"
            style="margin-bottom: 40px;"
            :disabled="!valid"
          >
          {{nombreboton}}
          </v-btn>
          <v-btn
            color="primary"
            class="mt-3"
            @click="nuevoyAsignarExamenes"
            style="margin-bottom: 40px;"
            v-show="botonasignar"
            :disabled="!valid"
          >
          REGISTRAR Y ASIGNAR EXÁMENES
          </v-btn>
        </v-row>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <DialogSimple
      :dialog.sync="dialog"
      :titulo.sync="dpacusTitulo"
      :width.sync="dpacusAncho"
    >
      <v-row
        justify="center"
        align="center"
        style="padding: 10px;"
      >
        <v-form ref="form2" v-model="valid2">
          <v-row class="mb-3">
            <v-col
              cols="12"
              sm="6"
            >
              <p class="primary--text">La contraseña debe seguir las siguientes reglas:</p>
              <ul>
                <li>Mínimo de 8 caracteres</li>
                <li>Máximo de 15 caracteres</li>
                <li>Al menos una letra mayúscula</li>
                <li>Al menos una letra minúscula</li>
                <li>Al menos un dígito</li>
                <li>Sin espacios en blanco</li>
                <li>Al menos una caracter especial (#, %, &, etc.)</li>
              </ul>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                label="Contraseña"
                v-model="datos.pass"
                :rules="passRules"
                counter
                maxlength="15"
                style="margin-bottom: -5px;"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass ? 'text' : 'password'"
                @click:append="showpass = !showpass"
                class="mb-6"
              ></v-text-field>
              <v-text-field
                label="Re ingrese la contraseña"
                v-model="datos.repass"
                :rules="passRules"
                counter
                maxlength="15"
                style="margin-bottom: 10px;"
                :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showpass2 ? 'text' : 'password'"
                @click:append="showpass2 = !showpass2"
              ></v-text-field>
              <v-row justify="center">
                <v-btn
                  color="primary"
                  class="mt-3"
                  @click="validaPass"
                >
                  ACEPTAR
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </DialogSimple>
    <AsignarExamenes :dialogAE.sync="dialogAsignarExamenes"/>
  </v-row>
 </DialogVentana>
</template>

<script>
import Vue from 'vue'
import ApiCon from '@/logic/apicon.js'
import Globales from '@/mixins/globales.vue'
import AsignarExamenes from '@/views/Paciente/asignarexamenes.vue'
import DialogVentana from '@/components/dialogventana.vue'
import DialogSimple from '@/components/dialogsimple.vue'
import VueMask from 'v-mask'
Vue.use(VueMask)

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    inputsizq: [],
    inputsder: [],
    requerido: v => !!v || 'Este campo es obligatorio',
    emailRules: [
      v => !!v || 'Este campo es obligatorio',
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'El email debe ser válido'
    ],
    passRules: [
      v => !!v || 'Este campo es obligatorio',
      v => !v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(v) || 'Cumpla las reglas, por favor'
    ],
    date: new Date().toISOString().substr(0, 10),
    menu1: false,
    fechanacvisible: true,
    sexos: ['Masculino', 'Femenino'],
    datos: { id: '', rut: '', nombre: '', segnombre: '', appaterno: '', apmaterno: '', sexo: '', edad: '', fechanac: '', direccion: '', comuna: '', sector: '', email: '', celular: '', prevision: '', pass: '', repass: '', tipo_cuenta: '' },
    comunas: [],
    nombreboton: '',
    dialog: false,
    showpass: false,
    showpass2: false,
    botonpass: false,
    sucursal: 'AFLAB Casa Matriz La Ligua',
    loading: false,
    passok: '',
    valid: false,
    valid2: false,
    id: 0,
    rutLabel: 'RUT (sin puntos, con guión y dígito verificador.)',
    emailLabel: 'Email',
    dialogAsignarExamenes: false,
    asignar: false,
    verPrevision: true,
    tiposCuenta: [],
    verTipoCuenta: false,
    disableTipoCuenta: false,
    disabledRut: false,
    dpacusTitulo: 'EDITAR CONTRASEÑA',
    dpacusAncho: 500,
    reglaUpload: [
      value => !value || value.size < 1000000 || 'El tamaño de la imagen de firma debe ser menor a 1 MB'
    ],
    firma: '',
    firmaVisible: false,
    labelFirma: 'Seleccione su imagen de firma',
    botonasignar: false,
    colorEditaPass: 'primary',
    botonEditaPass: ''
  }),
  components: {
    AsignarExamenes,
    DialogVentana,
    DialogSimple
  },
  props: ['modo', 'titulo', 'dialogPU'],
  methods: {
    nombreToUpper () {
      this.datos.nombre = this.datos.nombre.toUpperCase().trim()
    },
    segnombreToUpper () {
      this.datos.segnombre = this.datos.segnombre.toUpperCase().trim()
    },
    appaternoToUpper () {
      this.datos.appaterno = this.datos.appaterno.toUpperCase().trim()
    },
    apmaternoToUpper () {
      this.datos.apmaterno = this.datos.apmaterno.toUpperCase().trim()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    async cargarComunas () {
      this.loading = true
      const response = await ApiCon.get('comunas/todas')
      if (response.status === 200) {
        this.comunas = response.data
      } else { alert('Error en carga de comunas...') }
      this.loading = false
    },
    guardarDatos () {
      if (this.modo === 'nuevopaciente') {
        this.nuevoPaciente()
      } else if (this.modo === 'editarpaciente') {
        this.editarPaciente()
      } else if (this.modo === 'nuevousuario') {
        this.nuevoUsuario()
      } else if (this.modo === 'editarusuario' | this.modo === 'datospropios') {
        this.editarUsuario()
      }
    },
    cargarDatos () {
      this.cargarComunas()
      if (this.modo === 'nuevopaciente') {
        this.nombreboton = 'REGISTRAR'
        this.botonasignar = true
      } else if (this.modo === 'editarpaciente') {
        this.cargarDatosPaciente()
        this.nombreboton = 'GUARDAR'
        this.disabledRut = true
      } else if (this.modo === 'nuevousuario') {
        this.nombreboton = 'REGISTRAR'
        this.botonpass = true
        this.asignar = false
        this.verPrevision = false
        this.verTipoCuenta = true
        this.cargarTiposCuenta()
        this.estadoValidaPass()
      } else if (this.modo === 'editarusuario') {
        this.nombreboton = 'GUARDAR'
        this.botonpass = true
        this.asignar = false
        this.verPrevision = false
        this.verTipoCuenta = true
        this.disabledRut = true
        this.cargarDatosUsuario('editarusuario')
        this.cargarTiposCuenta()
        this.estadoValidaPass()
      } else if (this.modo === 'datospropios') {
        this.nombreboton = 'GUARDAR'
        this.botonpass = true
        this.asignar = false
        this.verPrevision = false
        this.verTipoCuenta = true
        this.disableTipoCuenta = true
        this.disabledRut = true
        this.cargarDatosUsuario('datospropios')
        this.cargarTiposCuenta()
        this.estadoValidaPass()
      }
    },
    async cargarTiposCuenta () {
      this.loading = true
      const response = await ApiCon.get('cuentas/tiposcuenta')
      if (response.status === 200) {
        this.tiposCuenta = response.data
      } else { alert('Error en cargarTiposCuenta...') }
      this.loading = false
    },
    async cargarDatosUsuario (tipo) {
      this.loading = true
      var response
      if (tipo === 'editarusuario') {
        response = await ApiCon.get1('cuentas/uno', this.$store.state.ids)
      } else {
        response = await ApiCon.get1('cuentas/propia', this.$session.get('usuario'))
      }
      if (response.status === 200) {
        this.datos = response.data
        this.sucursal = response.data.sucursal
        this.tipoCuentaChange()
      } else { alert('Error en cargarDatosUsuario...') }
      this.loading = false
    },
    async cargarDatosPaciente () {
      this.loading = true
      const response = await ApiCon.get1('pacientes/ficha', this.$store.state.ids)
      if (response.status === 200) {
        this.datos = response.data
      } else { alert('Error en cargarDatosPaciente...') }
      this.loading = false
    },
    async editarPaciente () {
      if (this.valid === true) {
        const ins = {
          ID: this.$store.state.ids,
          Rut: this.datos.rut,
          Nombre: this.datos.nombre,
          Segnombre: this.datos.segnombre,
          Appaterno: this.datos.appaterno,
          Apmaterno: this.datos.apmaterno,
          Sexo: this.datos.sexo,
          Fechanac: this.datos.fechanac,
          Direccion: this.datos.direccion,
          Comuna: this.datos.comuna,
          Sector: this.datos.sector,
          Email: this.datos.email,
          Celular: this.datos.celular,
          Prevision: this.datos.prevision,
          Usuario: this.$session.get('usuario')
        }
        this.loading = true
        const response = await ApiCon.post('pacientes/editar', ins)
        if (response.status === 200) {
          alert('Paciente editado :)')
          this.cargarTabla('pacientes/todos', 'cambiarTabla', 'cargarPacientes')
        } else { alert('Error en editarPaciente') }
        this.loading = false
      }
    },
    nuevoyAsignarExamenes () {
      this.asignar = true
      this.nuevoPaciente()
    },
    async nuevoPaciente () {
      if (this.valid === true) {
        const ins = {
          Rut: this.datos.rut,
          Nombre: this.datos.nombre,
          Segnombre: this.datos.segnombre,
          Appaterno: this.datos.appaterno,
          Apmaterno: this.datos.apmaterno,
          Sexo: this.datos.sexo,
          Fechanac: this.datos.fechanac,
          Direccion: this.datos.direccion,
          Comuna: this.datos.comuna,
          Sector: this.datos.sector,
          Email: this.datos.email,
          Celular: this.datos.celular,
          Prevision: this.datos.prevision,
          Sucursal: this.sucursal,
          Usuario: this.$session.get('usuario')
        }
        this.loading = true
        const response = await ApiCon.post('pacientes/insertar', ins)
        if (response.status === 200) {
          alert('Paciente registrado :)')
          if (this.asignar === true) {
            this.$store.commit('cambiarID', response.data)
            this.dialogAsignarExamenes = true
          }
          this.reset()
          this.$refs.form.reset()
          this.dialogInput = false
          this.cargarTabla('pacientes/todos', 'cambiarTabla', 'cargarPacientes')
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      }
    },
    async nuevoUsuario () {
      if (this.valid === true & this.valid2 === true) {
        const formData = new FormData()
        formData.append('firma', this.firma)
        formData.append('Rut', this.datos.rut)
        formData.append('Nombre', this.datos.nombre)
        formData.append('Segnombre', this.datos.segnombre)
        formData.append('Appaterno', this.datos.appaterno)
        formData.append('Apmaterno', this.datos.apmaterno)
        formData.append('Sexo', this.datos.sexo)
        formData.append('Fechanac', this.datos.fechanac)
        formData.append('Direccion', this.datos.direccion)
        formData.append('Comuna', this.datos.comuna)
        formData.append('Sector', this.datos.sector)
        formData.append('Email', this.datos.email)
        formData.append('Celular', this.datos.celular)
        formData.append('Pass', this.passok)
        formData.append('Sucursal', this.sucursal)
        formData.append('TipoCuenta', this.datos.tipo_cuenta)
        formData.append('Usuario', this.$session.get('usuario'))
        this.loading = true
        const response = await ApiCon.postUpload('cuentas/insertar', formData)
        if (response.status === 200) {
          alert('Usuario registrado :)')
          this.cargarTabla('cuentas/todos', 'cambiarTabla', 'cargarCuentas')
          this.reset()
          this.$refs.form.reset()
          this.dialogInput = false
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else { alert('Falta ingresar una contraseña.') }
    },
    async editarUsuario () {
      if (this.valid === true) {
        var idcuenta
        if (this.modo === 'editarusuario') {
          idcuenta = this.$store.state.ids
        } else {
          idcuenta = this.datos.id
        }
        const formData = new FormData()
        formData.append('firma', this.firma)
        formData.append('ID', idcuenta)
        formData.append('Rut', this.datos.rut)
        formData.append('Nombre', this.datos.nombre)
        formData.append('Segnombre', this.datos.segnombre)
        formData.append('Appaterno', this.datos.appaterno)
        formData.append('Apmaterno', this.datos.apmaterno)
        formData.append('Sexo', this.datos.sexo)
        formData.append('Fechanac', this.datos.fechanac)
        formData.append('Direccion', this.datos.direccion)
        formData.append('Comuna', this.datos.comuna)
        formData.append('Sector', this.datos.sector)
        formData.append('Email', this.datos.email)
        formData.append('Celular', this.datos.celular)
        if (this.valid2 === true) {
          formData.append('Pass', this.passok)
        }
        formData.append('Sucursal', this.sucursal)
        formData.append('TipoCuenta', this.datos.tipo_cuenta)
        formData.append('Usuario', this.$session.get('usuario'))
        this.loading = true
        const response = await ApiCon.postUpload('cuentas/editar', formData)
        if (response.status === 200) {
          alert('Usuario editado :)')
          this.cargarTabla('cuentas/todos', 'cambiarTabla', 'cargarCuentas')
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      }
    },
    tipoCuentaChange () {
      if (this.datos.tipo_cuenta === 'Administrador' | this.datos.tipo_cuenta === 'Tecnólogo Médico') {
        this.firmaVisible = true
        if (this.modo === 'editarusuario' | this.modo === 'datospropios') {
          if (this.datos.firma_cuenta !== '' & this.datos.firma_cuenta !== null) {
            this.labelFirma = 'Ya tiene una firma guardada. De todas maneras puede subir una nueva.'
          } else {
            this.labelFirma = 'Seleccione su imagen de firma'
          }
        }
      } else {
        this.firmaVisible = false
      }
    },
    preFirmaUpload () {
      if (this.dialogInput === true) {
        if (event.target.files.length > 0) {
          this.firma = event.target.files[0]
        }
      }
    },
    verDialogPass () {
      this.passok = ''
      this.estadoValidaPass()
      this.dialog = true
    },
    validaPaciente () {
      if (this.datos.rut !== '' & this.datos.nombre !== '' & this.datos.appaterno !== '' &
      this.datos.apmaterno !== '' & this.datos.sexo !== '' & this.datos.fechanac !== '' &
      this.datos.comuna !== '' & this.datos.email !== '' & this.datos.celular !== '') {
        return true
      } else {
        this.$refs.form.validate()
        this.validaSelects('paciente')
        this.asignar = false
        alert('Faltan datos por completar.')
        return false
      }
    },
    validaSelects (tipo) {
      if (tipo === 'usuario') {
        if (this.datos.tipo_cuenta === '') {
          this.tipocuentaback = 'error'
        } else { this.tipocuentaback = 'white' }
      }
    },
    validaPass () {
      if (this.valid2 === true) {
        if (this.datos.pass !== '' & this.datos.repass !== '') {
          if (this.datos.pass === this.datos.repass) {
            this.passok = this.datos.repass
            this.dialog = false
          } else { alert('La contraseña ingresada no coincide.') }
        } else { alert('Debe completar los 2 campos de contraseña.') }
      } else {
        alert('Compruebe que los campos estén completados y se cumplen sus reglas de ingreso.')
      }
      this.estadoValidaPass()
    },
    estadoValidaPass () {
      if (this.modo === 'nuevousuario') {
        this.colorEditaPass = 'primary'
        this.botonEditaPass = 'EDITAR CONTRASEÑA'
        if (this.passok !== '') {
          this.colorEditaPass = 'success'
          this.botonEditaPass = 'CONTRASEÑA LISTA'
        } else {
          this.colorEditaPass = 'error'
          this.botonEditaPass = 'FALTA CONTRASEÑA'
        }
      } else if (this.modo === 'editarusuario' | this.modo === 'datospropios') {
        this.colorEditaPass = 'success'
        this.botonEditaPass = 'EDITAR CONTRASEÑA'
        if (this.passok !== '') {
          this.colorEditaPass = 'success'
          this.botonEditaPass = 'CONTRASEÑA EDITADA'
        }
      }
    },
    validarRut () {
      this.datos.rut = this.validaRutFront(this.datos.rut)
      if (this.modo === 'nuevopaciente' | this.modo === 'editarpaciente') {
        this.existe('paciente')
      } else {
        this.existe('usuario')
      }
    },
    async existeEmail () {
      if (this.datos.email !== '') {
        if (this.modo === 'editarpaciente' | this.modo === 'editarusuario') {
          this.id = this.$store.state.ids
        } else {
          this.id = 0
        }
        this.emailLabel = 'Comprobando email...'
        const response = await ApiCon.get3('personas/existeemail', this.modo, this.datos.email, this.id)
        if (response.status === 200) {
          if (response.data === true) {
            //
          } else {
            alert('El email que ha ingresado ya se encuentra registrado.')
            this.datos.email = ''
          }
        } else { alert('Error en existeEmail...') }
        this.emailLabel = 'Email'
      }
    },
    async existe (tipo) {
      if (this.datos.rut !== '') {
        var response
        this.rutLabel = 'Comprobando RUT...'
        if (tipo === 'paciente') {
          response = await ApiCon.get3('pacientes/existepaciente', this.modo, this.datos.rut, this.$session.get('usuario'))
        } else {
          response = await ApiCon.get3('cuentas/existecuenta', this.modo, this.datos.rut, this.$session.get('usuario'))
        }
        if (response.status === 200) {
          if (response.data === false) {
            this.completaPersona(this.datos.rut)
          } else {
            alert('El rut se encuentra registrado en este laboratorio.')
            this.datos.rut = ''
          }
        } else { alert('Error en existe...') }
        this.rutLabel = 'RUT (sin puntos, con guión y dígito verificador.)'
      }
    },
    async completaPersona (rut) {
      this.loading = true
      if (this.modo === 'nuevopaciente' | this.modo === 'nuevousuario') {
        try {
          const response = await ApiCon.get1('personas/completapersona', rut)
          if (response.status === 200) {
            if (confirm('Los datos de esta persona están registrados en el sistema AFLAB. ¿Desea completar con estos datos?')) {
              this.datos = response.data
            } else {}
          } else { alert('Error en cargarDatosUsuario...') }
        } catch (err) {}
      }
      this.loading = false
    },
    reset () {
      this.datos.pass = ''
      this.datos.repass = ''
      this.passok = ''
      this.asignar = false
      this.verPrevision = true
      this.verTipoCuenta = false
      this.tiposCuenta = []
      this.disableTipoCuenta = false
      this.disabledRut = false
      this.firma = ''
      this.firmaVisible = false
      this.labelFirma = 'Seleccione su imagen de firma'
      this.botonasignar = false
    },
    noAccion () {
      //
    }
  },
  created () {
  },
  watch: {
    date (val) {
      this.datos.fechanac = this.formatDate(this.date)
    },
    dialogPU () {
      if (this.dialogPU === false) {
        if (this.modo === 'nuevopaciente' | this.modo === 'editarpaciente') {
          this.$refs.form.reset()
          this.reset()
        } else {
          this.$refs.form.reset()
          this.reset()
        }
      } else {
        this.cargarDatos()
      }
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogPU
      },
      set: function (newValue) {
        this.$emit('update:dialogPU', newValue)
      }
    }
  }
}
</script>
